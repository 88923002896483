import axios from "axios";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
var is_refeshing_token = false;

export default {
  install: (app) => {
    app.config.globalProperties.$request = async (option) => {
      const request = axios.create({
        baseURL:
          (option && option.baseURL) ||
          `${process.env.VUE_APP_BACK_END_URL}/api/`,
      });

      let times = 0;
      while (is_refeshing_token && times <= 100) {
        await delay(50);
        times++;
      }
      embedTokenToRequest(request, option);

      // check if token <= 15m => refresh token
      const expired_at = localStorage.getItem("expired_at");
      const remainTime = new Date(`${expired_at}+00:00`) - new Date();
      if (expired_at && remainTime <= 900000) {
        is_refeshing_token = true;
        // call api refresh token
        let result = await request({
          url: `user/refresh-token`,
          method: "POST",
        });
        const res = result.data;
        if (res.code == 200 && res.success) {
          // refresh token
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("expired_at", res.data.expired_at);
          embedTokenToRequest(request);
        }
        is_refeshing_token = false;
      }

      try {
        if (!option.isHideLoading) {
          app.config.globalProperties.$store.dispatch("loading", true);
        }
        let response = await request({
          responseType: option.responseType,
          url: option.url,
          method: option.method || "GET",
          data: option.data,
          params: option.params,
          timeout: option.timeout,
        });
        const res = response.data;
        if (!option.isHideLoading) {
          app.config.globalProperties.$store.dispatch("loading", false);
        }
        if (res.code != 200) {
          app.config.globalProperties.$store.dispatch("setIsUpdating", false);
        }
        if (res.code == 403) {
          app.config.globalProperties.$store.dispatch("loadingLayer", false);
          if (res.messages == "Permission Denied") {
            app.config.globalProperties.$store.dispatch(
              "setCompaniesSelected",
              null
            );
            app.config.globalProperties
              .$swal({
                text: res.messages,
                icon: "error",
                timer: "3000",
                width: "450",
                padding: "10px",
              })
              .then(() => {
                app.config.globalProperties.$router
                  .push("/companyManagement")
                  .then(() => {
                    location.reload();
                  });
                return;
              });

            return response;
          }
          localStorage.removeItem("token");
          localStorage.removeItem("expired_at");
          localStorage.removeItem("user");
          app.config.globalProperties.$store._state.data.loading = false;
          app.config.globalProperties.$store.dispatch("setToken", null);
          app.config.globalProperties.$store.dispatch("setKeySearch", "");
          app.config.globalProperties.$store.dispatch(
            "setKeySearchMailBox",
            ""
          );
          app.config.globalProperties.$store.dispatch("setUserKeySearch", "");
          app.config.globalProperties.$store.dispatch(
            "setInvoiceKeySearch",
            ""
          );
          app.config.globalProperties.$store.dispatch("setEmailQltk", null);
          app.config.globalProperties.$store.dispatch("setUsersUser", null);
          app.config.globalProperties.$store.dispatch(
            "setCompaniesSelected",
            null
          );
          app.config.globalProperties.$store.dispatch("setReportPeriod", null);
          app.config.globalProperties.$store.dispatch("setreportYear", null);
          app.config.globalProperties.$store.dispatch("setIsManual", false);
          localStorage.clear();
          app.config.globalProperties.$router.push("/login");
          return response;
        }
        if (res.code == 404) {
          app.config.globalProperties.$store.dispatch(
            "setCompaniesSelected",
            null
          );
          app.config.globalProperties.$router.push("/companyManagement");
        }
        return response;
      } catch (e) {
        if (e.code === 'ECONNABORTED') {
          throw e
        }
        const error = e.response || "";
        return error;
      } finally {
        app.config.globalProperties.$store.dispatch("loading", false);
      }
    };
  },
};

function embedTokenToRequest(request, option={}) {
  request.interceptors.request.use((config) => {
    if (localStorage.getItem("token") != undefined) {
      const token = option.token ? option.token : localStorage.getItem("token");
      config.headers["Authorization"] = "Bearer " + token;
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  });
}
