import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import state from "./state";
import * as getters from "./getters";
import * as mutations from "./mutations";
import * as actions from "./actions";

import login from "./modules/login";
import company from "./modules/company";
import invoices from "./modules/invoices";
import users from "./modules/users";
import report from "./modules/report";
import keepSearchCompany from "./modules/search/companySearch";
import keepSearchUser from "./modules/search/userSearch";
import keepSearchInvoice from "./modules/search/invoiceSearch";
import keepSearchInvoiceLog from "./modules/search/invoiceLog";
import keepSearchSummary from "./modules/search/summary";
import keepSearchGovInvoiceHistory from "./modules/search/govInvoiceHistory";
import emailQltk from "./modules/search/emailQltk";
import setRoleUser from "./modules/keepRoleUser";
import keepStatusUpdateInvoice from "./modules/keepStatusUpdateInvoice";
import mailBox from "./modules/search/mailBox";

export default createStore({
  state,
  getters,
  mutations,
  actions,

  modules: {
    login,
    company,
    invoices,
    users,
    keepSearchCompany,
    keepSearchUser,
    keepSearchInvoice,
    keepSearchInvoiceLog,
    keepSearchSummary,
    keepSearchGovInvoiceHistory,
    emailQltk,
    setRoleUser,
    report,
    keepStatusUpdateInvoice,
    mailBox,
  },

  plugins: [
    createPersistedState({
      paths: [
        "emailQltk",
        "keepSearchCompany",
        "keepSearchUser",
        "keepSearchInvoice",
        "keepSearchInvoiceLog",
        "keepSearchSummary",
        "keepSearchGovInvoiceHistory",
        "setRoleUser",
        "company",
        "mailBox",
      ],
    }),
  ],
});
