export const SET_COMPANIES = (state, companies) => {
  state.companies = companies;
};
export const SET_ALL_COMPANIES = (state, companies) => {
  state.allCompanies = companies;
};
export const SET_COMPANIES_SELECTED = (state, id) => {
  state.selectCurrentCompany = id;
};
export const SET_TARGET_COMPANY = (state, targetCompany) => {
  // Handle for select company name display
  if (targetCompany.id > -1) {
    targetCompany.text = `${targetCompany.tenDoanhNghiep} - ${targetCompany.mst}`
  }
  state.targetCompany = targetCompany;
};
export const SET_DECLARATION_TYPE = (state, data) => {
  state.declarationType = data;
};
export const SET_COMPANY_NAME = (state, data) => {
  state.companyName = data;
};
export const SET_START_YEAR = (state, year) => {
  state.startYear = year;
};
export const SET_QUERY = (state, query) => {
  state.query = query;
};
