export const setCompanies = ({ commit }, companies) => {
  commit("SET_COMPANIES", companies);
};
export const setAllCompanies = ({ commit }, companies) => {
  commit("SET_ALL_COMPANIES", companies);
};
export const setCompaniesSelected = ({ commit }, id) => {
  commit("SET_COMPANIES_SELECTED", id);
};
export const setTargetCompany = ({ commit }, targetCompany) => {
  commit("SET_TARGET_COMPANY", targetCompany);
};
export const setDeclarationType = ({ commit }, data) => {
  commit("SET_DECLARATION_TYPE", data);
};
export const setCompanyName = ({ commit }, data) => {
  commit("SET_COMPANY_NAME", data);
};
export const setStartYear = ({ commit }, year) => {
  commit("SET_START_YEAR", year);
};
export const setQuery = ({ commit }, year) => {
  commit("SET_QUERY", year);
};
