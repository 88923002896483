export const setToken = ({ commit }, token) => {
  commit("SET_TOKEN", token);
};

export const setAuthUser = ({ commit }, user) => {
  commit("SET_AUTH_USER", user);
};

export const setEmailUser = ({ commit }, email) => {
  commit("SET_EMAIL_USER", email);
};

export const setExpiredAt = ({ commit }, expired) => {
  commit("SET_EXPIRE_AT", expired);
};
