export const SET_TOKEN = (state, token) => {
  state.token = token;
  localStorage.setItem("token", token);
};

export const SET_AUTH_USER = (state, user) => {
  var userJson = JSON.stringify(user);
  localStorage.setItem("user", userJson);
  state.authUser = JSON.parse(localStorage.getItem("user"));
};

export const SET_EMAIL_USER = (state, email) => {
  state.authEmailUser = email;
};
export const SET_EXPIRE_AT = (state, expired) => {
  state.expired_at = expired;
};
