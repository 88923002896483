export const companies = (state) => {
  return state.companies;
};
export const getAllCompanies = (state) => {
  return state.allCompanies;
};
export const selectCurrentCompany = (state) => {
  let selectedCompany = null;
  if (state.selectCurrentCompany) selectedCompany = state.selectCurrentCompany;
  return selectedCompany;
};
export const declarationType = (state) => {
  return state.declarationType;
};
export const companyName = (state) => {
  return state.companyName;
};
export const getStartYear = (state) => {
  return state.startYear;
};
export const getQuery = (state) => {
  return state.query;
};
export const getTargetCompany = (state) => {
  return state.targetCompany;
};
