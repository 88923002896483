export default {
  companies: [],
  allCompanies: [],
  selectCurrentCompany: -1, // selected company Id only
  targetCompany: {}, // selected company Object - Legacy code
  declarationType: null,
  companyName: null,
  startYear: null,
  query: {
    page: 1,
    limit: 10,
    key_search: ""
  }
};
