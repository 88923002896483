import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "main",
    redirect: "/dashBoard",
    component: () => {
      return import("@/views/layouts/main/main.vue");
    },
    children: [
      {
        path: "/dashBoard",
        name: "dashBoard",
        component: () => {
          return import("@/views/pages/dashBoard/dashBoard.vue");
        },
      },
      {
        path: "/companyManagement",
        name: "companyManagement",
        component: () => {
          return import(
            "@/views/pages/companyManagement/companyManagement.vue"
          );
        },
      },
      {
        path: "/invoices",
        name: "invoices",
        redirect: "/invoices/listInvoices",
        component: () => {
          return import("@/views/pages/invoices/index.vue");
        },
        children: [
          {
            path: "/invoices/listInvoices",
            name: "listInvoices",
            component: () => {
              return import("@/views/pages/invoices/invoices.vue");
            },
          },
          {
            path: "/invoices/editInvoices/:invoiceId",
            name: "editInvoices",
            component: () => {
              return import(
                "@/views/pages/invoices/editInvoices/editInvoices.vue"
              );
            },
          },
        ],
      },
      {
        path: "/users",
        name: "users",
        component: () => {
          return import("@/views/pages/users/users.vue");
        },
      },
      {
        path: "/profile",
        name: "profile",
        component: () => {
          return import("@/views/pages/profile/profile.vue");
        },
      },
      {
        path: "/reports",
        name: "reports",
        redirect: "/reports/tax_return",
        component: () => {
          return import("@/views/pages/reports/reports/reports.vue");
        },
        children: [
          {
            path: "/reports/output",
            name: "output",
            component: () => {
              return import(
                "@/views/pages/reports/bangKeBanRa/bangKeBanRa.vue"
              );
            },
          },
          {
            path: "/reports/input",
            name: "input",
            component: () => {
              return import(
                "@/views/pages/reports/bangKeMuaVao/bangKeMuaVao.vue"
              );
            },
          },
          {
            path: "/reports/summary",
            name: "summary",
            component: () => {
              return import(
                "@/views/pages/reports/tongHopThueGtgt/tongHopThueGtgt.vue"
              );
            },
          },
          {
            path: "/reports/tax_return",
            name: "tax_return",
            component: () => {
              return import(
                "@/views/pages/reports/toKhaiThueGtgt/toKhaiThueGtgt.vue"
              );
            },
          },
          {
            path: "/reports/report_131",
            name: "report_131",
            component: () => {
              return import(
                "@/views/pages/reports/report_131/report_131.vue"
              );
            },
          },
          {
            path: "/reports/report_331",
            name: "report_331",
            component: () => {
              return import(
                "@/views/pages/reports/report_331/report_331.vue"
              );
            },
          },
        ],
      },
      {
        path: "/invoiceLog",
        name: "invoiceLog",
        component: () => {
          return import("@/views/pages/invoiceLog/invoiceLog.vue");
        },
      },
      {
        path: "/mailBox",
        name: "mailBox",
        redirect: "/mailBox/listMailBox",
        component: () => {
          return import("@/views/pages/mailBox/mailBox.vue");
        },
        children: [
          {
            path: "/mailBox/listMailBox",
            name: "listMailBox",
            component: () => {
              return import(
                "../views/pages/mailBox/listMailBox/listMailBox.vue"
              );
            },
          },
          {
            path: "/mailBox/detailMailBox/:mailId",
            name: "detailMailBox",
            component: () => {
              return import(
                "../views/pages/mailBox/detailMailBox/detailMailBox.vue"
              );
            },
          },
        ],
      },
      {
        path: "/summary",
        name: "invoice_summary",
        component: () => {
          return import("@/views/pages/summary/summary.vue");
        },
      },
      {
        path: "/gov/invoices",
        name: "connect-gov",
        component: () => {
          return import(
            "@/views/pages/gov-invoices/gov-invoices.vue"
          );
        },
      },
      {
        path: "system-statistic",
        name: "system-statistic",
        component: () => {
          return import(
            "@/views/pages/system-statistic/system-statistic.vue"
          );
        },
      },

    ],
  },
  {
    path: "/auth",
    name: "auth",
    component: () => {
      return import("@/views/auth/auth.vue");
    },
    children: [
      {
        path: "/login",
        name: "login",
        component: () => {
          return import("@/views/login/login.vue");
        },
      },
      {
        path: "/register",
        name: "register",
        component: () => {
          return import("@/views/resgiter/register.vue");
        },
      },
      {
        path: "/forgotpassword",
        name: "forgotPassword",
        component: () => {
          return import("@/views/forgotPassword/forgotPassword.vue");
        },
      },
      {
        path: "/otp",
        name: "otp",
        component: () => {
          return import("@/views/OTP/otp.vue");
        },
      },
      {
        path: "/password-reset/:uid/:token",
        name: "resetPassword",
        component: () => {
          return import("@/views/resetPassword/resetPassword.vue");
        },
      },
      {
        path: "/activate-email/:uid/:token",
        name: "activeAccount",
        component: () => {
          return import("@/views/activeAccount/activeAccount.vue");
        },
      },
      {
        path: "/sendEmail",
        name: "sendEmail",
        component: () => {
          return import("@/views/sendEmail/sendEmail.vue");
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: () => {
      return import("@/views/pages/notFound/notFound.vue");
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const role = store.state;
  const roleITaxGeneral = [
    "listInvoices",
    "editInvoices",
    "output",
    "input",
    "summary",
    "tax_return",
    "invoiceLog",
    "listMailBox",
    "detailMailBox",
  ];
  if (
    localStorage.getItem("token") &&
    localStorage.getItem("is_invite") == "true" &&
    from.name &&
    from.name == "profile"
  ) {
    next({ name: "profile" });
    return;
  }
  if (
    !localStorage.getItem("token") &&
    [
      "login",
      "sendEmail",
      "activeAccount",
      "forgotPassword",
      "resetPassword",
    ].indexOf(to.name) == -1 &&
    !from.name
  ) {
    next({ name: "login" });
  } else if (
    localStorage.getItem("token") &&
    to.name == "dashBoard" &&
    role &&
    role.setRoleUser &&
    role.setRoleUser.role == "ITaxGeneral"
  ) {
    next({ name: "companyManagement" });
  } else if (
    (localStorage.getItem("token") &&
      ["register", "login", "forgotPassword", "sendEmail"].indexOf(to.name) !=
        -1) ||
    (to.name == "users" &&
      role &&
      role.setRoleUser &&
      role.setRoleUser.role == "CompanyUser") ||
    (roleITaxGeneral.indexOf(to.name) != -1 &&
      role &&
      role.setRoleUser &&
      role.setRoleUser.role == "ITaxGeneral")
  ) {
    next({ name: "companyManagement" });
  } else if (
    [
      "register",
      "sendEmail",
      "activeAccount",
      "forgotPassword",
      "resetPassword",
    ].indexOf(from.name) == -1 &&
    to.name == "login" &&
    from.name
  ) {
    location.reload();
    return;
  } else {
    next();
  }
});

export default router;

